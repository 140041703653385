import "index.css";
import hljs from "./highlight.min.js";

// Import all JavaScript & CSS files from src/_components
import components from "bridgetownComponents/**/*.{js,jsx,js.rb,css}"

// Stuff from FE dev of theme
window.onload = () => document.body.classList.remove('preload');

const toggleButtons = document.querySelectorAll('.toggle-btn');
const mobileMenu = document.getElementById('mobile-menu');

toggleButtons.forEach(button => button.addEventListener('click', e => {
  mobileMenu.classList.toggle('hidden');
  document.body.classList.toggle('overflow-hidden');
}));
//
// const projectsLink = document.querySelector('.products-link');
//
// projectsLink.addEventListener('click', e => {
//   const subMenus = e.target.nextElementSibling;
//   const arrowIcon = e.target.querySelector('svg');
//
//   arrowIcon.classList.toggle('-rotate-180');
//   subMenus.classList.toggle('hidden');
// });

hljs.highlightAll();

document.addEventListener('DOMContentLoaded', (event) => {
  document.querySelectorAll('p code').forEach((el) => {
    hljs.highlightElement(el);
  });
});
